.product-carousel-block{
    padding:0 0 2rem;
    .product-carousel{
        margin-top: 4rem;
    }
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        height: inherit !important;
        margin: 0 0.5rem;
    }
    .slick-list {
        margin: 0 -0.5rem;
    }
}
