.main-hero{
    position:relative;
    background:{
        size:cover;
        repeat:no-repeat;
        position:center;
    }
    &::before {
        content: "";
        position: absolute;
        inset:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 0;
    }
    .container-fluid{
        position: relative;
        z-index: 1;
        h1, p, a {
            text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
            color:white;
        }
    }
}

.hero-block{
    padding-top: 0;
    .hero-image{
        width:100vw;
        height:auto;
    }
    &.video-container {
        position: relative;
        overflow: hidden;
        padding-top: 2rem;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        video, img{
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            z-index: 0;
        }
        .container{
            z-index: 1;
            position: relative;
            text-align: center;
            color: #dc0000;
            padding: 10px;
        }
    }
    
    .container-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        padding-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        h2{
            font-size: 2rem;
            font-weight: 600;
        }
        .text{
            text-align: center;
            font-size: 1.2rem;
        }
        @include for-tablet-landscape-up {
            h2{
                font-size: 4rem;
            }
            .text{
                font-size: 1.5rem;
            }
        }
    }
}
