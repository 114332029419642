// .mobile-icon{
//     display: inline-block;    
//     &:hover{
//         cursor: pointer;
//     }
//     @include for-tablet-portrait-up {
//         display: none;
//     }
// }
.search-wrapper{
    // .search-form{
    //     display: none;
    // }
    @include for-tablet-portrait-up {
        display: flex;
        align-items: center;
        justify-content: end;
        .search-form{
            display: block;
        }
    }
    &.active{
        .search-form{
            display: block;
            margin: 1rem 0 0 0!important;
        }
    }
}

.icon-large{
    font-size: 1.4rem;
    line-height: 1.4rem;
}

tr{
    &.bg-success{
        background-color: rgba(2, 255, 66, 0.4) !important;
        &:hover{
            td{
                background-color: rgba(2, 255, 66, 0.6) !important;
            }
        }
    }
    &.bg-danger{
        background-color: rgba(251, 67, 67, 0.5) !important;
        &:hover{
            td{
                background-color: rgba(251, 67, 67, 0.7) !important;
            }
        }
    }
}

table{
    color:black !important;
}