.grid {
    margin: 0 auto;
}
.grid-sizer,
.grid-item {
    width: 140px;
    margin-bottom: 1rem;
    @include for-tablet-portrait-up {
        width: 350px;
    
    }
}