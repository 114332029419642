@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");


@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/utilities";
@import "bootstrap/scss/utilities/api";

@import "modules/variables";

// Modules
@import "modules/media_query_mixins";
@import "modules/theme-media-query";
@import "modules/typography";
@import "modules/buttons";
@import "modules/form";

// Blocks
@import "blocks/hero-block";
@import "blocks/masonry";

@import "modules/global";
@import "modules/slick";


.accordion-button:focus{
    outline:none!important;
    box-shadow:none!important;
}
.main-menu.navbar-nav{
    >.nav-item{
        a.nav-link{
            background-color:transparent;
        }
    }
}

.logo-dashboard{
    @include for-phone-only {
        width:120px;
    }
}

.mobile-toggle{
    color:white;
}

.navbar-dark .navbar-toggler:focus {
    background-color: transparent;
    color: white;
}

